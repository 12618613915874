// Create a MutationObserver to watch for new nodes that include <p class="input-error">
let observer = new MutationObserver((mutationsList, observerInstance) => {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
            // Check every added node (and its descendants) for a matching element
            for (const node of mutation.addedNodes) {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    // Check if this node or any of its descendants match the selector
                    const errorElement =
                        node.matches('p.input-error') ? node : node.querySelector('p.input-error');
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        return;
                    }
                }
            }
        }
    }
});

// Observer configuration: watch for nodes being added anywhere in the subtree
const config = { childList: true, subtree: true };

// Attach the observer to the form with class "stack"
const form = document.querySelector('form');
if (form) {
    observer.observe(form, config);
}
